import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element.js';
import ElementUI from 'element-ui';
// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
import http from './plugins/http';
import MetaInfo from 'vue-meta-info';
import MultiUpload from '@/components/MultiUpload';
import CropUpload from '@/components/CropUpload';
import SingleUpload from '@/components/SingleUpload';
import VueQuillEditor from 'vue-quill-editor';
import common from './mixins/common';
import RichText from '@/components/RichText';
import 'swiper/css/swiper.css';
import './styles/app.less';
Vue.prototype.$colors = {
    prim: '#ff8700',
    warn: '#00684f'
};

Vue.prototype.$EventBus = new Vue();

Vue.use(MetaInfo);
Vue.use(VueQuillEditor);
Vue.use(http);

Vue.mixin(common);

Vue.config.productionTip = false;
Vue.component('multi-upload', MultiUpload);
Vue.component('crop-upload', CropUpload);
Vue.component('single-upload', SingleUpload);
const observerMap = new Map();
Vue.directive('change', {
    // 当被绑定的元素插入到 DOM 中时……
    bind(el, binding) {
        const config = {
            attributes: true,
            subtree: true
        };
        const callback = function () {
            binding.value();
        };

        var _observer = new MutationObserver(callback);
        _observer.observe(el, config);
        observerMap.set(el, _observer);
    },
    unbind(el) {
        observerMap.get(el).disconnect();
    }
});

Vue.component('rich-text', RichText);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

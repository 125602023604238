import Vue from 'vue';
import VueRouter from 'vue-router';
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
import store from '../store';
import {
    MessageBox
} from 'element-ui';
Vue.use(VueRouter);

const routes = [{
        path: '/',
        redirect: 'home'
    },
    {
        path: '/',
        component: () => import('../views/Index.vue'),
        children: [{
                path: '/home',
                name: 'home',
                component: () => import('../views/Home.vue'),
                meta: {
                    title: '首页'
                }
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('../views/About.vue'),
                meta: {
                    title: '关于我们',
                    prePath: '/about'
                }
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/Login.vue'),
                meta: {
                    title: '登录'
                }
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('../views/Register.vue'),
                meta: {
                    title: '注册'
                }
            },
            {
                path: '/registration',
                name: 'registration',
                component: () => import('../views/Registration.vue'),
                meta: {
                    title: '注册结果',
                    checkLogin: true
                }
            },
            {
                path: '/enterprise',
                name: 'enterprise',
                component: () => import('../views/Enterprise.vue'),
                meta: {
                    title: '机构认证',
                    checkLogin: true
                }
            },
            {
                path: '/personal',
                name: 'personal',
                component: () => import('../views/Personal.vue'),
                meta: {
                    title: '个人认证',
                    checkLogin: true
                }
            },
            {
                path: '/authenticationfail',
                name: 'authenticationfail',
                component: () => import('../views/Authenticationfail.vue'),
                meta: {
                    title: '个人认证失败',
                    checkLogin: true
                }
            },
            {
                path: '/authenticationsfail',
                name: 'authenticationsfail',
                component: () => import('../views/Authenticationsfail.vue'),
                meta: {
                    title: '机构认证失败',
                    checkLogin: true
                }
            },
            {
                path: '/authentications',
                name: 'authentications',
                component: () => import('../views/Authentications.vue'),
                meta: {
                    title: '机构认证结果',
                    checkLogin: true
                }
            },
            {
                path: '/authentication',
                name: 'authentication',
                component: () => import('../views/Authentication.vue'),
                meta: {
                    title: '个人认证结果',
                    checkLogin: true
                }
            },
            {
                path: '/allsearch',
                name: 'allsearch',
                component: () => import('../views/Allsearch.vue'),
                meta: {
                    title: '搜索'
                }
            },
            {
                path: '/',
                component: () => import('../views/legal/Index.vue'),
                children: [{
                        path: '/policy',
                        name: 'policy',
                        component: () => import('../views/legal/Policy.vue'),
                        meta: {
                            title: '政策',
                            isNavation: true
                        }
                    },
                    {
                        path: '/legal',
                        name: 'legal',
                        component: () => import('../views/legal/Legal.vue'),
                        meta: {
                            title: '法律',
                            isNavation: true
                        }
                    },
                    {
                        path: '/intellectualProperty',
                        name: 'intellectualProperty',
                        component: () => import('../views/legal/IntellectualProperty.vue'),
                        meta: {
                            title: '知识产权',
                            prePath: '/intellectualProperty?type=DECLASSIFICATION',
                            // isNavation: true,
                            checkLogin: true,
                            needAuth: true
                        }
                    },
                    {
                        path: '/digital',
                        name: 'digital',
                        component: () => import('../views/legal/Digital.vue'),
                        meta: {
                            title: '技术/产品/企业',
                            prePath: '/intellectualProperty?type=DECLASSIFICATION',
                            // isNavation: true,
                            checkLogin: true,
                            needAuth: true
                        }
                    },
                    {
                        path: '/expert',
                        name: 'expert',
                        component: () => import('../views/legal/Expert.vue'),
                        meta: {
                            title: '专家',
                            prePath: '/intellectualProperty?type=DECLASSIFICATION',
                            // isNavation: true,
                            checkLogin: true,
                            needAuth: true
                        }
                    }
                ]
            },
            {
                path: '/',
                component: () => import('../views/market/Index.vue'),
                children: [{
                        path: '/patent',
                        name: 'patent',
                        component: () => import('../views/market/Patent.vue'),
                        meta: {
                            title: '专利',
                            prePath: '/patent?caseType=GENERAL',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/patentDemand',
                        name: 'patentDemand',
                        component: () => import('../views/market/PatentDemand.vue'),
                        meta: {
                            title: '专利',
                            prePath: '/patentDemand?caseType=GENERAL',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/technologyProduct',
                        name: 'technologyProduct',
                        component: () => import('../views/market/TechnologyProduct.vue'),
                        meta: {
                            title: '技术/产品',
                            prePath: '/patent?caseType=DECLASSIFICATION',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/technicalManager',
                        name: 'technicalManager',
                        component: () => import('../views/market/TechnicalManager.vue'),
                        meta: {
                            title: '技术经理人',
                            prePath: '/technicalManager',
                            // checkLogin: true,
                            isNavation: true
                        }
                    },
                    {
                        path: '/purchase',
                        name: 'purchase',
                        component: () => import('../views/market/Purchase.vue'),
                        meta: {
                            title: '军采信息',
                            prePath: '/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/goods',
                        name: 'goods',
                        component: () => import('../views/market/Goos.vue'),
                        meta: {
                            title: '货物',
                            prePath: '/patent?caseType=DECLASSIFICATION',
                            isNavation: true
                        }
                    },
                    {
                        path: '/service',
                        name: 'service',
                        component: () => import('../views/market/Service.vue'),
                        meta: {
                            title: '服务',
                            prePath: '/patent?caseType=DECLASSIFICATION',
                            isNavation: true
                        }
                    },
                    {
                        path: '/successCases',
                        name: 'successCases',
                        component: () => import('../views/market/SuccessCases.vue'),
                        meta: {
                            title: '专利',
                            prePath: '/successCases?category=DECLASSIFICATION',
                            isNavation: true,
                            // checkLogin: true
                        }
                    },
                    {
                        path: '/successCaseslogo',
                        name: 'successCaseslogo',
                        component: () => import('../views/market/successCasesLogo.vue'),
                        meta: {
                            title: '商标',
                            prePath: '/successCases?category=DECLASSIFICATION',
                            isNavation: true,
                            // checkLogin: true
                        }
                    },
                    {
                        path: '/successCopyright',
                        name: 'successCopyright',
                        component: () => import('../views/market/SuccessCopyright.vue'),
                        meta: {
                            title: '版权/软著',
                            prePath: '/successCases?category=DECLASSIFICATION',
                            isNavation: true,
                            // checkLogin: true
                        }
                    },
                    {
                        path: '/successProduct',
                        name: 'successProduct',
                        component: () => import('../views/market/SuccessProduct.vue'),
                        meta: {
                            title: '技术/产品',
                            prePath: '/successCases?category=DECLASSIFICATION',
                            isNavation: true,
                            // checkLogin: true
                        }
                    },
                    {
                        path: '/funding',
                        name: 'funding',
                        component: () => import('../views/market/Funding.vue'),
                        meta: {
                            title: '资金',
                            prePath: '/patentDemand?caseType=DECLASSIFICATION',
                            isNavation: true
                        }
                    },
                    {
                        path: '/copyright',
                        name: 'copyright',
                        component: () => import('../views/market/Copyright.vue'),
                        meta: {
                            title: '版权/软著',
                            prePath: '/patent?caseType=DECLASSIFICATION',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/copyrightDemand',
                        name: 'copyrightDemand',
                        component: () => import('../views/market/CopyrightDemand.vue'),
                        meta: {
                            title: '版权/软著',
                            prePath: '/patentDemand?type=COPY',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/techProductDemand',
                        name: 'techProductDemand',
                        component: () => import('../views/market/TechProductDemand.vue'),
                        meta: {
                            title: '技术/产品',
                            prePath: '/patentDemand?caseType=DECLASSIFICATION',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/logo',
                        name: 'logo',
                        component: () => import('../views/market/Logo.vue'),
                        meta: {
                            title: '商标',
                            prePath: '/patent?caseType=DECLASSIFICATION',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/logoDemand',
                        name: 'logoDemand',
                        component: () => import('../views/market/LogoDemand.vue'),
                        meta: {
                            title: '商标',
                            prePath: '/patentDemand?caseType=DECLASSIFICATION',
                            // checkLogin: true
                            // isNavation: true
                        }
                    },
                    {
                        path: '/serviceProvider',
                        name: 'serviceProvider',
                        component: () => import('../views/market/ServiceProvider.vue'),
                        meta: {
                            title: '服务机构',
                            prePath: '/patent?caseType=DECLASSIFICATION',
                            isNavation: true
                        }
                    }
                ]
            },
            {
                path: '/agent',
                name: 'agent',
                component: () => import('../views/agent/Index.vue'),
                meta: {
                    title: '知产运营服务',
                    isNavation: true
                }
            },
            {
                path: '/statistics',
                name: 'statistics',
                component: () => import('../views/statistics/Index.vue'),
                meta: {
                    title: '数据',
                    isNavation: true
                }
            },
            {
                path: '/statisticsDetail',
                name: 'statisticsDetail',
                component: () => import('../views/statistics/Detail.vue'),
                meta: {
                    title: '数据详情',
                    needHistory: true,
                    pageDeep: 'third'
                }
            },
            {
                path: '/patentDetail',
                name: 'patentDetail',
                component: () => import('../views/detail/Patent.vue'),
                meta: {
                    title: '专利详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/logoDetail',
                name: 'logoDetail',
                component: () => import('../views/detail/Logo.vue'),
                meta: {
                    title: '商标详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/copyrightDetail',
                name: 'copyrightDetail',
                component: () => import('../views/detail/Copyright.vue'),
                meta: {
                    title: '版权详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/technologyProductDetail',
                name: 'technologyProductDetail',
                component: () => import('../views/detail/TechnologyProduct.vue'),
                meta: {
                    title: '产品详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/patentDemandDetail',
                name: 'patentDemandDetail',
                component: () => import('../views/detail/PatentDemand.vue'),
                meta: {
                    title: '专利详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/logoDemandDetail',
                name: 'logoDemandDetail',
                component: () => import('../views/detail/LogoDemand.vue'),
                meta: {
                    title: '商标详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/copyrightDemandDetail',
                name: 'copyrightDemandDetail',
                component: () => import('../views/detail/CopyrightDemand.vue'),
                meta: {
                    title: '版权详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/technologyProductDemandDetail',
                name: 'technologyProductDemandDetail',
                component: () => import('../views/detail/TechnologyProductDemand.vue'),
                meta: {
                    title: '产品详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/technicalManagerDetail',
                name: 'technicalManagerDetail',
                component: () => import('../views/detail/TechnicalManager.vue'),
                meta: {
                    title: '技术经理人详情',
                    needHistory: true,
                    pageDeep: 'third'
                }
            },
            {
                path: '/expertDetail',
                name: 'expertDetail',
                component: () => import('../views/detail/ExpertDetail.vue'),
                meta: {
                    title: '专家详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true,
                    needAuth: true
                }
            },
            {
                path: '/purchaseDetail',
                name: 'purchaseDetail',
                component: () => import('../views/detail/PurchaseDetail.vue'),
                meta: {
                    title: '军采详情',
                    needHistory: true,
                    pageDeep: 'third'
                }
            },
            {
                path: '/legalDetail',
                name: 'legalDetail',
                component: () => import('../views/detail/Legal.vue'),
                meta: {
                    title: '政策详情',
                    needHistory: true,
                    pageDeep: 'third'
                }
            },
            {
                path: '/newsDetail',
                name: 'newsDetail',
                component: () => import('../views/detail/newsContent.vue'),
                meta: {
                    needHistory: true,
                    pageDeep: 'third'
                }
            },
            {
                path: '/intellectualPropertyDetail',
                name: 'intellectualPropertyDetail',
                component: () => import('../views/detail/IntellectualProperty.vue'),
                meta: {
                    title: '知产详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true,
                    needAuth: true
                }
            },
            {
                path: '/digitalDetail',
                name: 'digitalDetail',
                component: () => import('../views/detail/Digital.vue'),
                meta: {
                    title: '技术详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true,
                    needAuth: true
                }
            },
            {
                path: '/digitalDetails',
                name: 'digitalDetails',
                component: () => import('../views/detail/Digitals.vue'),
                meta: {
                    title: '企业详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true,
                    needAuth: true
                }
            },
            {
                path: '/fundingDetail',
                name: 'fundingDetail',
                component: () => import('../views/detail/Funding.vue'),
                meta: {
                    title: '资金详情',
                    needHistory: true,
                    pageDeep: 'third'
                }
            },
            {
                path: '/successDetail',
                name: 'successDetail',
                component: () => import('../views/detail/SuccessDetail.vue'),
                meta: {
                    title: '案例详情',
                    needHistory: true,
                    pageDeep: 'third',
                    checkLogin: true
                }
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('../views/news/Index.vue'),
                meta: {
                    title: '新闻动态'
                }
            },
            {
                path: '/newsList',
                name: 'newsList',
                component: () => import('../views/news/List.vue'),
                meta: {
                    title: '知产咨询动态'
                }
            },
            {
                path: '/',
                component: () => import('../views/user/UserMain.vue'),
                children: [{
                        path: '/userEdit',
                        name: 'userEdit',
                        component: () => import('../views/user/UserEdit.vue'),
                        meta: {
                            title: '个人资料',
                            checkLogin: true
                        }
                    },
                    {
                        path: '/personalEdit',
                        name: 'personalEdit',
                        component: () => import('../views/user/PersonalEdit.vue'),
                        meta: {
                            title: '编辑资料',
                            checkLogin: true
                        }
                    },
                    {
                        path: '/newsInfo',
                        name: 'newsInfo',
                        component: () => import('../views/user/NewsInfo.vue'),
                        meta: {
                            title: '消息列表',
                            checkLogin: true
                        }
                    },
                    {
                        path: '/patentEdit',
                        name: 'patentEdit',
                        component: () => import('../views/user/publish/PatentEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/patentDemandEdit',
                        name: 'patentDemandEdit',
                        component: () => import('../views/user/publish/patentDemandEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/LogoEdit',
                        name: 'LogoEdit',
                        component: () => import('../views/user/publish/LogoEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/logoDemandEdit',
                        name: 'logoDemandEdit',
                        component: () => import('../views/user/publish/logoDemandEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/CopyrightEdit',
                        name: 'CopyrightEdit',
                        component: () => import('../views/user/publish/CopyrightEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/CopyrightDemandEdit',
                        name: 'CopyrightDemandEdit',
                        component: () => import('../views/user/publish/CopyrightDemandEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/serviceProviderEdit',
                        name: 'serviceProviderEdit',
                        component: () => import('../views/user/publish/ServiceProviderEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/serviceDemandEdit',
                        name: 'serviceDemandEdit',
                        component: () => import('../views/user/publish/serviceDemandEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/technicalEdit',
                        name: 'technicalEdit',
                        component: () => import('../views/user/publish/technicalEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/TechnologyProductEdit',
                        name: 'TechnologyProductEdit',
                        component: () => import('../views/user/publish/TechnologyProductEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/fundingEdit',
                        name: 'fundingEdit',
                        component: () => import('../views/user/publish/FundingEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/TechProductDemandEdit',
                        name: 'TechProductDemandEdit',
                        component: () => import('../views/user/publish/TechProductDemandEdit.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true,
                            noCheckStatus: true,
                            prePath: '/publish/patent?caseType=DECLASSIFICATION'
                        }
                    },
                    {
                        path: '/',
                        component: () => import('../views/user/publish/Index.vue'),
                        meta: {
                            title: '我发布的',
                            checkLogin: true
                        },
                        children: [
                            //菜单从后台读取,我发布的模块路由命名规则为/publish/+path
                            {
                                path: '/publish/patent',
                                name: 'minePublishPatent',
                                component: () => import('../views/user/publish/Patent.vue'),
                                meta: {
                                    title: '我发布的',
                                    checkLogin: true,
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION'
                                }
                            },
                            {
                                path: '/publish/patentDemand',
                                name: 'minePublishpatentDemand',
                                component: () => import('../views/user/publish/patentDemand.vue'),
                                meta: {
                                    title: '我发布的',
                                    checkLogin: true,
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION'
                                }
                            },
                            {
                                path: '/publish/logo',
                                name: 'minePublishLogo',
                                component: () => import('../views/user/publish/Logo.vue'),
                                meta: {
                                    title: '商标',
                                    checkLogin: true,
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION'
                                }
                            },
                            {
                                path: '/publish/logoDemand',
                                name: 'minePublishlogoDemand',
                                component: () => import('../views/user/publish/logoDemand.vue'),
                                meta: {
                                    title: '商标',
                                    checkLogin: true,
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION'
                                }
                            },
                            {
                                path: '/publish/copyright',
                                name: 'minePublishCopyright',
                                component: () => import('../views/user/publish/Copyright.vue'),
                                meta: {
                                    title: '版权/软著',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/copyrightDemand',
                                name: 'minePublishcopyrightDemand',
                                component: () => import('../views/user/publish/copyrightDemand.vue'),
                                meta: {
                                    title: '版权/软著',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/serviceProvider',
                                name: 'minePublishserviceProvider',
                                component: () => import('../views/user/publish/ServiceProvider.vue'),
                                meta: {
                                    title: '服务机构',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/serviceDemand',
                                name: 'minePublishsserviceDemand',
                                component: () => import('../views/user/publish/serviceDemand.vue'),
                                meta: {
                                    title: '服务',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/funding',
                                name: 'minePublishfunding',
                                component: () => import('../views/user/publish/Funding.vue'),
                                meta: {
                                    title: '资金',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/technicalManager',
                                name: 'minePublishtechnicalManager',
                                component: () => import('../views/user/publish/TechnicalManager.vue'),
                                meta: {
                                    title: '技术经理人',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/TechnologyProduct',
                                name: 'minePublishTechnologyProduct',
                                component: () => import('../views/user/publish/TechnologyProduct.vue'),
                                meta: {
                                    title: '技术/产品',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            },
                            {
                                path: '/publish/TechProductDemand',
                                name: 'minePublishTechProductDemand',
                                component: () => import('../views/user/publish/TechProductDemand.vue'),
                                meta: {
                                    title: '技术/产品',
                                    prePath: '/publish/patent?caseType=DECLASSIFICATION',
                                    checkLogin: true
                                }
                            }
                        ]
                    },
                    {
                        path: '/release',
                        name: 'release',
                        component: () => import('../views/user/Release.vue'),
                        meta: {
                            title: '发布供给信息',
                            checkLogin: true
                        }
                    },
                    {
                        path: '/demandPatent',
                        name: 'demandPatent',
                        component: () => import('../views/user/Demandpatent.vue'),
                        meta: {
                            title: '发布需求信息',
                            checkLogin: true
                        }
                    },
                    {
                        path: '/security',
                        name: 'security',
                        component: () => import('../views/user/Security.vue'),
                        meta: {
                            title: '账号安全',
                            checkLogin: true
                        }
                    }
                ]
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition;
            }
        }
        if (!to.meta.isNavation) {
            return {
                x: 0,
                y: 0
            };
        }
    }
});

router.beforeEach((to, from, next) => {
    // console.log(from);
    // console.log(to);
    let historys = JSON.parse(sessionStorage.getItem('ZHIRONG_historys') || '[]');
    if (to.meta.needHistory && from.path !== '/' && to.path !== from.path) {
        historys = [
            ...historys,
            {
                path: from.path,
                query: from.query,
                name: document.title,
                prePath: from.meta.prePath || from.path
            }
        ];
        sessionStorage.setItem('ZHIRONG_historys', JSON.stringify(historys));
    } else if (!to.meta.needHistory) {
        sessionStorage.removeItem('ZHIRONG_historys');
    }

    // if (to.meta.pageDeep === 'third') {
    //     if (historys.length > 0) {
    //         to.meta.prePath = historys[historys.length - 1].prePath;
    //     }
    // }

    store.commit('setHistorys', historys);
    if (!store.state.userInfo && to.path !== 'login') {
        store
            .dispatch('getUserInfo')
            .then(() => {
                next();
            })
            .catch(() => {
                if (to.meta.checkLogin) {
                    next(false);
                    MessageBox.confirm('用户未登录，是否立即登录', '提示', {
                            confirmButtonText: '立即登录',
                            cancelButtonText: '取消'
                        })
                        .then(() => {
                            // this.$store.commit('loginVerification', true);
                            router.push('/login')
                            // router.push({
                            //     name: "home"
                            // })
                        })
                        .catch(() => {});
                    // next('/login');
                } else {
                    let info = store.state.personalInfo || store.state.institutionInfo;
                    if (to.meta.needAuth && (!info || info.status !== 'PASS')) {
                        next(false);

                        MessageBox.confirm('用户未认证，是否立即认证', '提示', {
                                confirmButtonText: '立即认证',
                                cancelButtonText: '取消'
                            })
                            .then(() => {
                                router.push('/userEdit')
                                // if (store.state.personalInfo && info.status === 'PENDING') {
                                //     router.push('/authentication');
                                // } else if (store.state.personalInfo && info.status === 'DENY') {
                                //     router.push('/Authenticationfail');
                                // } else if (store.state.institutionInfo && info.status === 'DENY') {
                                //     router.push('/Authenticationsfail');
                                // } else if (!info) {
                                //     router.push('/userEdit');
                                // } else if (store.state.personalInfo) {
                                //     router.push('/personal');
                                // } else {
                                //     router.push('/authentications');
                                // }
                            })
                            // .then(() => {
                            //     if (info && info.status === 'DENY') {
                            //         router.push('/authentication');
                            //     } else if (!info) {
                            //         router.push('/registration');
                            //     } else if (store.state.personalInfo) {
                            //         router.push('/personal');
                            //     } else {
                            //         router.push('/authentications');
                            //     }
                            // })
                            .catch(() => {});
                    } else {
                        next();
                    }
                }
            });
    } else {
        let info = store.state.personalInfo || store.state.institutionInfo;
        if (to.meta.needAuth && (!info || info.status !== 'PASS')) {
            next(false);

            MessageBox.confirm('用户未认证，是否立即认证', '提示', {
                    confirmButtonText: '立即认证',
                    cancelButtonText: '取消'
                })
                .then(() => {
                    router.push('/userEdit')
                    // console.log(store.state.personalInfo);
                    // if (store.state.personalInfo && info.status === 'PENDING') {
                    //     router.push('/authentication');
                    // } else if (store.state.personalInfo && info.status === 'DENY') {
                    //     router.push('/Authenticationfail');
                    // } else if (store.state.institutionInfo && info.status === 'DENY') {
                    //     router.push('/Authenticationsfail');
                    // } else if (!info) {
                    //     router.push('/userEdit');
                    // } else if (store.state.personalInfo) {
                    //     router.push('/personal');
                    // } else {
                    //     router.push('/authentications');
                    // }
                })
                // .then(() => {
                //     if (info && info.status === 'DENY') {
                //         router.push('/authentication');
                //     } else if (!info) {
                //         router.push('/registration');
                //     } else if (store.state.personalInfo) {
                //         router.push('/personal');
                //     } else {
                //         router.push('/authentications');
                //     }
                // })
                .catch(() => {});
        } else {
            next();
        }
    }
});

export default router;
<template>
    <section>
        <el-upload
            list-type="picture-card"
            :action="uploadUrl"
            :headers="headers"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-upload="handleBefore"
            :on-exceed="onExceed"
            :file-list="fileList"
            multiple
            :limit="limit"
        >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
                <slot></slot>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <el-image
            style="width: 0; height: 0; position: absolute"
            ref="preview"
            :src="previewUrl"
            :preview-src-list="previewList"
        >
        </el-image>
    </section>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        this.updateFileList(this.value);
    },
    props: {
        value: Array,
        usePrefix: {
            type: Boolean,
            default: true
        },
        url: {
            type: String
        },
        size: {
            tupe: Number,
            // 单位M
            default: 1
        },
        limit: {
            type: [Number, undefined],
            default: undefined
        }
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            uploadUrl: '',
            realFileList: [],
            previewUrl: '',
            previewList: []
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + localStorage.getItem('webToken')
            };
        }
    },
    methods: {
        handleRemove(file, fileList) {
            this.realFileList = fileList;
            this.$emit(
                'input',
                fileList.map(i => i.value)
            );
        },
        handleBefore(file) {
            // console.log(file);
            const isLt = file.size / 1024 / 1024 < this.size;
            const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
            // console.log(isJPG);
            if (!isJPG) {
                this.$message.error('上传图片只能是JPG、PNG格式');
            }
            if (!isLt) {
                this.$message.error('文件大小不能超过' + this.size + ' MB!');
            }
            return isJPG && isLt;
        },
        onExceed() {
            this.$message.error(`最多上传${this.limit}个文件`);
        },
        handlePictureCardPreview(file) {
            this.previewUrl = file.value;
            this.previewList = this.fileList.map(i => i.value);
            this.$refs.preview.showViewer = true;
        },
        handleSuccess(res, file, fileList) {
            if (res instanceof Array) {
                file.value = res[0];
            } else {
                file.value = res;
            }
            this.realFileList = fileList;
            this.$emit(
                'input',
                fileList.map(i => i.value)
            );
        },
        updateFileList(list) {
            if (!list) {
                list = [];
            } else if (typeof list == 'string') {
                list = list.split(',');
            }
            for (let i = 0; i < list.length; i++) {
                if (!this.fileList[i]) {
                    this.fileList[i] = {
                        value: list[i],
                        url: list[i]
                    };
                } else if (this.fileList[i].value !== list[i]) {
                    this.fileList[i] = {
                        value: list[i],
                        url: list[i]
                    };
                }
            }
            this.fileList.splice(list.length);
            // this.fileList = list.map(i => {
            //     return {
            //         value: i,
            //         url: i,
            //     };
            // });
        }
    },
    watch: {
        value(val, value) {
            console.log(value);
            if (JSON.stringify(val) == JSON.stringify(this.realFileList.map(i => i.value))) {
                return;
            }
            this.updateFileList(val);
        }
    }
};
</script>
<style lang="less" scoped>
.el-upload__tip {
    color: #c8c9cc;
    font-size: 14px;
    line-height: 24px;
    margin-top: 6px;
    // transform: translateY(-8px);
}
/deep/.el-upload-list--picture-card {
    display: inline-block;
    line-height: 0;
    .el-upload-list__item {
        margin-bottom: 0;
        display: inline-block;
    }
}
</style>

import axios from 'axios';
import qs from 'qs';
/* eslint-disable */
let baseUrl = 'http://localhost:8080';
switch (process.env.NODE_ENV) {
    case 'development':
        baseUrl = 'https://www.bxgxzc.com';
        // baseUrl = 'http://192.168.50.190:8080';
        // baseUrl = 'http://localhost:8080';
        // baseUrl = 'http://192.168.50.190:8080';
        break;
    case 'test':
        baseUrl = 'http://localhost:8080';
        break;
    case 'production':
        baseUrl = '../../';
        break;
}
const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.request.use(
    function (config) {
        config.headers = config.headers || {};
        let token = localStorage.getItem('webToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        let errorData = {};
        if (!error.response) {
            errorData = {
                error: '网络错误，请检查网络链接'
            };
        } else {
            errorData = error.response.data;
            // if (401 === error.response.status) {
            //     if (router.currentRoute.name !== 'login') {
            //         router.replace({
            //             name: 'login',
            //             params: {
            //                 from: router.currentRoute.name
            //             }
            //         });
            //     } else {
            //     }
            // }
        }
        console.log(typeof errorData);
        if (typeof errorData != 'object') {
            errorData = {
                error: '请求失败' + error.response.status
            };
        }
        return Promise.reject(errorData);
    }
);

const http = {
    get(url, params) {
        params = params || {};
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(
                    url,
                    {
                        params: params
                    },
                    {
                        withCredentials: true
                    }
                )
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
    post(url, body, options) {
        options = options || {};
        body = body || {};
        if (!(body instanceof FormData)) {
            if (options.body !== 'json') {
                body = qs.stringify(body);
            }
        }
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(url, body, {
                    withCredentials: true
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }
};
export default {
    axios: axiosInstance,
    http: http,
    install(_Vue, options) {
        _Vue.prototype.$baseUrl = baseUrl;
        _Vue.prototype.$axios = axiosInstance;
        _Vue.prototype.$http = http;
    }
};

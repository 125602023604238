<template>
    <div class="editor">
        <editor :value="value" @input="onInput" :init="init" />

        <div class="pla" v-if="!value">
            请输入详情内容，可自由编辑文字版式，可添加图片和视频，图片支持jpg/png/gif格式，单张图片不超过1M，上传本地视频时仅支持mp4格式，大小不超过50M
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import '../utils/zh_CN';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/imagetools';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/icons/default/index';
import '../plugins/indent2em';
export default {
    props: ['value', 'disabled'],
    components: { Editor },
    data() {
        return {
            init: {
                language: 'zh_CN',
                selector: 'textarea',
                menubar: false,
                branding: false,
                statusbar: false,
                height: 400,
                toolbar:
                    'undo redo | indent2em bold italic strikethrough forecolor backcolor fontsizeselect lineheight  | image media link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | preview fullscreen code help',
                plugins: [
                    'advlist indent2em autolink lists link image charmap print preview anchor textcolor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table contextmenu paste code help imagetools'
                ],
                lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
                fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                images_upload_url: this.$baseUrl + '/upload/file',
                images_upload_handler: (blobInfo, success, failure) => {
                    let formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    this.$axios
                        .post('/upload/file', formData)
                        .then(res => {
                            success(res.data);
                        })
                        .catch(e => {
                            failure(e);
                        });
                },
                init_instance_callback: editor => {
                    this.editor = editor;
                },
                file_picker_types: 'media',
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                visualblocks_default_state: true,
                end_container_on_empty_block: true,
                file_picker_callback: cb => {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'video/*');
                    input.onchange = () => {
                        var file = input.files[0];

                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            let formData = new FormData();
                            formData.append('file', file);
                            this.$axios
                                .post('/upload/file', formData)
                                .then(res => {
                                    cb(res.data);
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        };
                    };

                    input.click();
                }
            },
            content: ''
        };
    },
    methods: {
        onInput(val) {
            this.$emit('input', val);
        }
    }
};
</script>

<style lang="less" scoped>
.editor {
    position: relative;
    background-color: #fff;
    .pla {
        font-size: 14px;
        color: #c8c9cc;
        line-height: 24px;
        position: absolute;
        top: 50px;
        left: 15px;
        z-index: 0;
    }
}
</style>

<style lang="less">
.tox-tinymce {
    position: relative;
    z-index: 1;
}
.tox-tinymce-aux {
    z-index: 2500 !important;
}

.tox .tox-edit-area__iframe {
    background-color: transparent;
    margin-left: 7px;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import http from '../plugins/http';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
        keeps: [],
        historys: [],
        institutionInfo: null,
        personalInfo: null,
        showAdd: false,
        flag: 0,
        loginHome: false
        // loginType: false
    },
    mutations: {
        loginVerification(state, loginHome) {
            console.log(loginHome)
            state.loginHome = loginHome
        },
        modifyFlag(state, flag) {
            state.flag = flag;
        },
        setKeeps(state, keeps) {
            state.keeps = keeps;
        },
        setHistorys(state, historys) {
            state.historys = historys;
        },
        updateUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        updateInstitutionInfo(state, institutionInfo) {
            state.institutionInfo = institutionInfo;
        },
        updatepersonalInfo(state, personalInfo) {
            state.personalInfo = personalInfo;
        },
        updateShowAdd(state, showAdd) {
            state.showAdd = showAdd;
        }
        // loginTypeFn(state, payload) {
        //     state.loginType = payload;
        // }
    },
    actions: {
        getUserInfo(context) {
            return http.http.get('/user/my').then(res => {
                context.commit('updateUserInfo', res);
                console.log(res);
                let info = res.authorities.find(item => {
                    return item.name == 'ROLE_INSTITUTION' || item.name == 'ROLE_PERSONAL';
                });

                if (info && info.name == 'ROLE_INSTITUTION') {
                    return context.dispatch('getInstitutionInfo');
                } else if (info) {
                    return context.dispatch('getpersonalInfo');
                }

                return Promise.resolve(res);
            });
        },
        getInstitutionInfo(context) {
            return http.http.get('/institution/my').then(res => {
                context.commit('updateInstitutionInfo', res);
                console.log(res);
                return Promise.resolve(res);
            });
        },
        getpersonalInfo(context) {
            return http.http.get('/personal/my').then(res => {
                console.log(res);
                context.commit('updatepersonalInfo', res);
                return Promise.resolve(res);
            });
        }
    }
});
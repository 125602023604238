import http from "../plugins/http";

const flags = new Map([
    ['tradingMethodOption', 24],
    ['patentTypeOptions', 25],
    ['patentStatusOptions', 26],
    ['intePropLawStatus',27],
    ['typeOptions',28],
    ['tradingMethodOptionsDemands',29],
    ['tradingMethodOptionsDemand',30]
])

var checkList = [];

export function checkSetting(keys = [], cb) {
    let flagKeys = [...flags.keys()];
    keys.forEach(item => {
        if (flagKeys.includes(item) && !checkList.includes(item)) {
            cb(flags.get(item), item)
        }
    })
}
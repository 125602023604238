<template>
    <el-upload
        class="single-upload"
        :action="uploadUrl"
        :headers="headers"
        :show-file-list="false"
        :on-success="onSuccess"
        :before-upload="beforeUpload"
        :disabled="disabled"
    >
        <div></div>
        <div class="wrapper">
            <img v-if="imageUrl" :src="imageUrl" class="upload-image" />
            <i v-else class="el-icon-plus single-uploader-icon"></i>
            <div v-if="loading" class="loading">
                <i class="el-icon-loading"></i>
            </div>
            <div class="modify_avatar" v-if="modifyAvatar">修改头像</div>
        </div>
        <div slot="tip" class="el-upload__tip">
            <slot></slot>
        </div>
    </el-upload>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        this.updateImageUrl(this.value);
    },
    props: {
        value: String,
        usePrefix: {
            type: Boolean,
            default: true
        },
        modifyAvatar: {
            type: Boolean,
            default: false
        },
        size: {
            tupe: Number,
            // 单位M
            default: 1
        },
        url: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            imageUrl: '',
            loading: false,
            uploadUrl: ''
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + localStorage.getItem('webToken')
            };
        }
    },
    methods: {
        onSuccess(res, file) {
            this.loading = false;
            this.imageUrl = URL.createObjectURL(file.raw);
            var newVal = '';
            if (res instanceof Array) {
                newVal = res[0];
            } else {
                newVal = res;
            }
            this.$emit('input', newVal);
        },
        onError(err, file, fileList) {
            console.log(err);
            console.log(file);
            console.log(fileList);
            this.loading = false;
        },
        beforeUpload(file) {
            const isLt = file.size / 1024 / 1024 < this.size;
            const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
            if (!isJPG) {
                this.$message.error('上传图片只能是JPG、JPEG、PNG格式');
            }
            if (!isLt) {
                this.$message.error('文件大小不能超过' + this.size + ' MB!');
            }
            return isJPG && isLt;
        },
        updateImageUrl(url) {
            this.imageUrl = url;
        }
    },
    watch: {
        value(val) {
            this.updateImageUrl(val);
        }
    }
};
</script>
<style lang="less" scoped>
.single-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fbfdff;

    &:hover {
        border-color: @prim;
    }
}
.modify_avatar {
    width: 96px;
    height: 36px;
    border: 1px solid #dcdfe6;
    font-size: 13px;
    font-weight: 400;
    color: #939599;
    line-height: 36px;
    margin-left: 78px;
    margin-top: 20px;
    text-align: center;
}
.upload-image {
    height: 178px;
    display: block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        border-color: @prim;
    }
}

.wrapper {
    position: relative;
}

.single-upload .el-upload {
    position: relative;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    color: #333;
    font-size: 24px;
}
</style>

import dayjs from 'dayjs';
import {
    checkSetting
} from '../utils/getVariables.js'
export default {
    computed: {
        isLogin() {
            return !!this.$store.state.userInfo;
        }
    },
    methods: {
        getImg(imgs = '') {
            if (!imgs) {
                imgs = '';
            }
            if (!(imgs instanceof Array)) {
                imgs = imgs.split(',');
            }

            imgs = imgs.filter(item => {
                return !!item;
            });
            if (imgs.length > 0) {
                return imgs[0];
            } else {
                return require('../assets/logo2.png');
            }
        },
        formatTime(date, rule = 'YYYY/MM/DD') {
            return dayjs(new Date(date)).format(rule);
        },
        all(link) {
            this.$router.push(link);
        },
        getTimeBefor(lng = -1, type = 'w') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')].join(
                ','
            );
        },
        getTimeOnem(lng = -1, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')].join(
                ','
            );
        },
        getTimeTom(lng = -2, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')].join(
                ','
            );
        },
        getTimeTherrm(lng = -3, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')].join(
                ','
            );
        },
        getTimeTherrms(lng = -10, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')].join(
                ','
            );
        },
        getLabelName(val = '', list = []) {

            let info = list.find(item => {
                return item.value === val;
            });

            return info ? info.label : val;
        },
        showList(list = [], tag = ',') {
            if (!list) {
                return '';
            } else {
                if (!(list instanceof Array)) {
                    list = list.split(',');
                }
                return list.join(tag);
            }
        },
        sendCode(phone) {
            this.sending = true;
            this.$http
                .get('/sms/sendVerify', {
                    phone: phone
                })
                .then(() => {
                    this.sending = false;
                    this.checkTime();
                })
                .catch(e => {
                    this.sending = false;
                    this.$message.error(e.error);
                });
        },
        checkTime() {
            this.time = 60;
            let i = setInterval(() => {
                this.time--;
                if (this.time === 0) {
                    clearInterval(i);
                }
            }, 1000);
        },
        pdCode(phone, code) {
            this.$http
                .get('/sms/verify', {
                    phone: phone,
                    code: code
                })
                .then(() => {})
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        checkLogin(checkAuth = false) {
            if (!this.isLogin) {
                this.$confirm('用户未登录，是否立即登录', '提示', {
                        confirmButtonText: '立即登录',
                        cancelButtonText: '取消'
                    })
                    .then(() => {
                        // this.$store.commit('loginVerification', true);
                        this.$router.push('/login')
                        // this.$router.push({
                        //     name: "home"
                        // });
                    })
                    .catch(() => {});

                return Promise.reject({
                    error: '未登录'
                });
            } else if (checkAuth) {
                let info = this.$store.state.personalInfo || this.$store.state.institutionInfo;
                if (info && info.status === 'PASS') {
                    return Promise.resolve();
                } else {
                    this.$confirm('用户未认证，是否立即认证', '提示', {
                            confirmButtonText: '立即认证',
                            cancelButtonText: '取消'
                        })
                        .then(() => {
                            this.$router.push('/userEdit')
                            // if (this.$store.state.personalInfo && info.status === 'PENDING') {
                            //     this.$router.push('/authentication');
                            // } else if (this.$store.state.personalInfo && info.status === 'DENY') {
                            //     this.$router.push('/Authenticationfail');
                            // } else if (this.$store.state.institutionInfo && info.status === 'DENY') {
                            //     this.$router.push('/Authenticationsfail');
                            // } else if (!info) {
                            //     this.$router.push('/userEdit');
                            // } else if (this.$store.state.personalInfo) {
                            //     this.$router.push('/personal');
                            // } else {
                            //     this.$router.push('/authentications');
                            // }
                        })
                        .catch(() => {});
                    return Promise.reject({
                        error: '未认证'
                    });
                }
            } else {
                return Promise.resolve();
            }
        },
        connectKefu() {
            this.$http.get('/sysConfig/get/CONNECT_PHONE').then(res => {
                this.$alert(res.value, '提示', {
                    confirmButtonText: '确定',
                    center: true,
                    callback: () => {}
                });
            });
        },
        getSetting(flag = 1, keyName) {
            return this.$http
                .post('/setting/byFlag', {
                    flag: flag
                })
                .then(res => {
                    let list = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })
                    if (keyName) {
                        this[keyName] = list;
                    }
                    return Promise.resolve(list)
                })
        }
    },
    created() {
        if (this.needChange || this.list) {
            let list = Object.keys(this.$data)
            checkSetting(list, this.getSetting)
        }
    }
};